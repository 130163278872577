.push-left {
  margin-left: 10px;
}

.warning-icon {
  color: rgb(240, 96, 44);
}

.text-top {
  vertical-align: text-top;
}
