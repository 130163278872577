.App-logo {
  height: 40px;
}

.activeLink > Button {
  background-color: #2525ca;
  color: #e0e0e0;
}

.activeLink > Button:hover {
  background-color: #2222be;
  color: #dbdbdb;
}
