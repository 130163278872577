.App {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
  color: white;
}

.App-body {
  flex-grow: 1;
  padding: 15px;
  overflow-y: auto;
}

.App-footer {
  padding: 10px;
}
